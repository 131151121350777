import {
  forEach, isNull, get, cloneDeep,
} from 'lodash';
import { isString } from 'util';

import ApiManager from 'utils/ApiManager';

import * as GraphQLTransforms from 'utils/GraphQLTransforms';

import config from 'config';
import { convertUrlStringToArray } from '../shared.utils';

export const initialState = {
  businessPremises: [],
  selectedOptions: {
    priceMinSelected: undefined,
    priceMaxSelected: undefined,
    priceNettoMinSelected: undefined,
    priceNettoMaxSelected: undefined,
    showOnlyReadyLocals: undefined,
    sortSelected: 'local-asc',
    surfaceMinSelected: undefined,
    surfaceMaxSelected: undefined,
    typeSelected: config.search.typeIds.flat,
    roomMinSelected: undefined,
    roomMaxSelected: undefined,
    floorMinSelected: undefined,
    floorMaxSelected: undefined,
    viewsSelected: [],
    investmentsSelected: [],
    featuresSelected: {},
    onlyPromo: 0,
  },
};

export const actionTypes = {
  LOAD_DATA_INIT: 'SEARCH/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'SEARCH/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'SEARCH/LOAD_DATA_ERROR',
  LOAD_DATA: 'SEARCH/LOAD_DATA',
  CHANGE_FILTERS: 'SEARCH/CHANGE_FILTERS',
  CHANGE_SORT_OPTION: 'SEARCH/CHANGE_SORT_OPTION',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0];

      return {
        ...state,
        businessPremises: GraphQLTransforms.getNode(data).map((entry) => {
          const output = {
            id: entry.id,
            title: entry.title,
            priceNetto: parseFloat(entry.priceNetto),
            price: parseFloat(entry.price),
            priceM2: parseFloat(entry.priceM2),
            surface: parseFloat(entry.surface),
            status: entry.status.entity,
            floor: { id: entry.floor || 0, name: entry.floor || 0 },
            localNumber: `${entry.investment.entity.buildingNumber}/${entry.localNumber}`,
            nr: entry.localNumber,
            isLocalCommercial: entry.isLocalCommercial,
            building: {
              id: entry.investment.entity.id,
              title: entry.investment.entity.buildingNumber,
              slug: entry.investment.entity.slug,
            },
            investment: entry.investment.entity.fieldBuildingInvestment.entity,
            type: entry.type.entity,
            endDate: new Date(get(entry, 'investment.entity.fieldBuildingFinishDate.value', 0)).getTime(),
            rooms: { id: entry.rooms || 0, name: entry.rooms || 0 },
            roomsList: [],
            direction: entry.direction.map((el) => el.entity).filter((el) => !isNull(el)),
            checkURL: entry.checkURL,
          };

          const businessPremiseData = entry.businessPremiseData.entity;
          if (businessPremiseData) {
            output.businessPremiseData = {
              pdfLink: get(businessPremiseData, 'pdfLink.entity.url', null),
              address: businessPremiseData.address,
              description: businessPremiseData.description,
              reference: businessPremiseData.reference && businessPremiseData.reference.uri,
              galleryThumbnails: businessPremiseData.galleryThumbnails
                .map(GraphQLTransforms.imageStyle),
              galleryPhotos: businessPremiseData.galleryPhotos
                .map(GraphQLTransforms.imageStyle),
              forLease: businessPremiseData.forLease,
            };
          }

          return output;
        }),
      };
    }
    case actionTypes.LOAD_DATA: {
      const selectedOptions = cloneDeep(state.selectedOptions);
      const data = action.data;

      forEach(initialState.selectedOptions, (selectedOptionInitialValue, key) => {
        if (data[key] !== undefined) {
          selectedOptions[key] = data[key];
        } else {
          selectedOptions[key] = selectedOptionInitialValue;
        }
      });

      // create array
      selectedOptions.investmentsSelected = convertUrlStringToArray(
        selectedOptions.investmentsSelected,
      ).map((element) => Number(element));

      // create array
      selectedOptions.viewsSelected = convertUrlStringToArray(
        selectedOptions.viewsSelected,
      ).map((element) => Number(element));

      // pass object query hack
      selectedOptions.featuresSelected = {};
      const featuresSelectedKeys = convertUrlStringToArray(data.featuresSelectedKeys);
      const featuresSelectedValues = convertUrlStringToArray(
        data.featuresSelectedValues,
      ).map((element) => Number(element));

      if (featuresSelectedKeys.length === featuresSelectedValues.length) {
        featuresSelectedKeys.forEach((key, index) => {
          selectedOptions.featuresSelected[key] = featuresSelectedValues[index];
        });
      }

      // parse numberlike strings
      forEach(selectedOptions, (element, key) => {
        if (!isString(element)) return;
        const number = Number(element);
        if (!Number.isNaN(number)) selectedOptions[key] = number;
      });

      return {
        ...state,
        selectedOptions,
      };
    }
    case actionTypes.CHANGE_FILTERS: {
      return {
        ...state,
        selectedOptions: action.data,
      };
    }
    case actionTypes.CHANGE_SORT_OPTION: {
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          sortSelected: action.data,
        },
      };
    }
    default:
      return state;
  }
};

export const changeFilters = (data) => ({
  type: actionTypes.CHANGE_FILTERS,
  data,
});

export const changeSortOption = (data) => ({
  type: actionTypes.CHANGE_SORT_OPTION,
  data,
});

export const loadServiceAreaData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(
          limit: 100, 
          filter: {
            conditions: [
              {field: "type", value: ["local"]},
              {field: "status", value: ["1"]},
              # {field: "field_local_hide_sale", value: ["0"]},
              {field: "field_local_type.entity.tid", value: ["11"]},
              {field: "field_local_service_area_data", operator: IS_NOT_NULL}
              {field: "field_local_service_area_data.entity.field_service_area_sold", value: ["0"]},
              {field: "field_local_service_area_data.entity.field_service_area_leased", value: ["0"]},
            ]
          }
        ) {
          entities {
            ... on NodeLocal {
              id: nid,
              title,
              surface: fieldLocalSurface,
              price: fieldLocalTotalPrice,
              priceNetto: fieldLocalTotalPriceNetto,
              priceM2: fieldLocalPriceM2,
              pricePromo: fieldLocalTotalPricePromo,
              priceM2Promo: fieldLocalPriceM2Promo,
              promoType: fieldLocalPromoType,
              promoFromDate: fieldLocalPromoTo { 
                value
              }
              promoToDate: fieldLocalPromoFrom {
                value
              }
              status: fieldLocalStatus {
                entity {
                  id: tid,
                  name
                }
              },
              type: fieldLocalType {
                entity {
                  id: tid,
                  name
                }
              },
              checkURL: fieldLocalCustomLink
              direction: fieldLocalWorldDirection {
                entity {
                  id: tid,
                  name
                }
              },
              localNumber: fieldLocalNumber,
              imageUrl: fieldLocal2d {
                entity {
                  url
                }
              },
              isLocalCommercial: fieldIsCommercialLocal,
              roomsList: fieldLocalRoomsList {
                entity {
                  ... on ParagraphRoom {
                    id,
                    name: fieldRoomName {
                      value
                    },
                    surface: fieldRoomSurface,
                  }
                }
              },
              rooms: fieldLocalRooms,
              floor: fieldLocalFloor,
              features: fieldLocalFeatures {
                entity {
                  ... on TaxonomyTermLocalFeatures {
                    id: tid,
                    name,
                    img: fieldLocalFeatureIcon {
                      entity {
                        url
                      },
                    },
                  }
                }
              },
              investment: fieldLocalBuilding {
                entity {
                  ... on NodeBuilding {
                    id: nid,
                    buildingNumber: fieldBuildingNumber,
                    fieldBuildingFinishDate {
                      value,
                    },
                    fieldBuildingInvestment {
                      entity {
                        ... on NodeInvestment {
                          slug: fieldInvestmentSlug
                          hasFiltersOn: fieldInvestmentUseFilters
                          published: entityPublished
                        }
                        id: nid,
                        name: title,
                      }
                    }
                  }
                }
              }
              businessPremiseData: fieldLocalServiceAreaData {
                entity {
                  ... on ParagraphServiceArea {
                    address: fieldServiceAreaAddress,
                    galleryThumbnails: fieldServiceAreaGallery {
                      derivative(style: SERVICEAREAGALLERYTHUMB) {
                        url
                      }
                    }
                    galleryPhotos: fieldServiceAreaGallery {
                      derivative(style: ORIGINAL) {
                        url
                      }
                    },
                    reference: fieldServiceAreaReference {
                      uri
                    },
                    description: fieldServiceAreaDescription,
                    forLease: fieldServiceAreaForLease,
                    pdfLink: fieldServiceAreaPdfFile {
                      ... on FieldParagraphServiceAreaFieldServiceAreaPdfFile {
                        entity {
                          url
                        }
                      }
                    }
                  }                  
                }
              }
            }
          }
        }
      }
    `,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};

export const loadData = (data) => (dispatch) => {
  let newViewsSelected = data.viewsSelected;
  let newFeaturesSelectedKeys = data.featuresSelectedKeys;
  let newFeaturesSelectedValues = data.featuresSelectedValues;

  if (data.viewsSelected && data.viewsSelected.includes('/')) {
    newViewsSelected = data.viewsSelected.split('/');
  }

  if (data.featuresSelectedKeys && data.featuresSelectedKeys.includes('/')) {
    newFeaturesSelectedKeys = data.featuresSelectedKeys.split('/');
  }

  if (data.featuresSelectedValues && data.featuresSelectedValues.includes('/')) {
    newFeaturesSelectedValues = data.featuresSelectedValues.split('/');
  }

  dispatch({
    type: actionTypes.LOAD_DATA,
    data: {
      ...data,
      viewsSelected: newViewsSelected,
      featuresSelectedKeys: newFeaturesSelectedKeys,
      featuresSelectedValues: newFeaturesSelectedValues,
    },
  });

  return dispatch(loadServiceAreaData());
};
